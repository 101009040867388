import React, { useState, useEffect, createContext, useContext } from 'react';

export const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
    const [startTime, setStartTime] = useState(null);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [timerOn, setTimerOn] = useState(false);

    useEffect(() => {
        let interval = null;

        if (timerOn && startTime) {  
            interval = setInterval(() => {
                const now = new Date();
                const difference = now - startTime;
                setElapsedTime(difference); 
            }, 1000); 
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [timerOn, startTime]); 

    const startTimer = () => {
        const start = new Date();
        setStartTime(start); 
        setTimerOn(true);
    };

    const resetTimer = () => {
        setElapsedTime(0); 
        setTimerOn(false); 
        setStartTime(null); 
    };

    
    const formattedTime = new Date(elapsedTime).toISOString().substr(11, 8);

    const value = { formattedTime, startTimer, resetTimer };

    return (
        <TimerContext.Provider value={value}>
            {children}
        </TimerContext.Provider>
    );
};

export function Timer() {
    const { formattedTime, startTimer, resetTimer } = useContext(TimerContext);

    return (
        <div className="text-center">
            <h1 className="text-2xl font-bold">Timer: {formattedTime}</h1>
            <button className="mx-2 p-2 bg-blue-500 text-white rounded" onClick={startTimer}>Start</button>
            <button className="mx-2 p-2 bg-red-500 text-white rounded" onClick={resetTimer}>Reset</button>
        </div>
    );
}
